import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import toast, { Toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  const [roomId, setRoomId] = useState(97188);
  const [userName, setUserName] = useState("");

  const createNewRoom = (e) => {
    e.preventDefault();
    const id = uuid();
    setRoomId(id);
    toast.success("New room created");
  };

  const joinRoom = () => {
    if (!roomId || !userName) {
      toast.error("Please enter User name");
      return;
    }
    navigate(`/editor/${roomId}`, {
      state: {
        userName,
      },
    });
  };

  const handleInputUp = (e) => {
    if (e.code === "Enter") joinRoom();
  };

  return (
    <div className="homePageWrapper">
      <div className="formWrapper">
        <a href="https://solutiontechie.com" target={"_blank"}>
          <img
            src="/code-sync.png"
            alt="code-syn-logo"
            className="homePageLogo"
          />
        </a>
        <h4 className="mainLabel">
          Please enter your user name on your choice & code with experts!
        </h4>
        <div className="inputGroup">
          {/* <input
            type="text"
            className="inputBox"
            placeholder="ROOM ID"
            readOnly
            value={roomId}
            onChange={(e) => setRoomId(e.target.value)}
            onKeyUp={handleInputUp}
          /> */}
          <input
            type="text"
            className="inputBox"
            placeholder="USER NAME"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            onKeyUp={handleInputUp}
          />
          <button onClick={joinRoom} className="btn joinBtn">
            Start Coding
          </button>
          <span className="createInfo">
            <a href="https://solutiontechie.com" target={"_blank"}>
              Visit our blogs
            </a>
          </span>
        </div>
      </div>
      <footer></footer>
    </div>
  );
};

export default HomePage;
